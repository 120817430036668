import * as React from "react";
import "../../../styles/otter-keg/Literboard.scss";
import { DateRangeInput3, type DateRange } from "@blueprintjs/datetime2";
import { useFirebaseConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { OtterKegState } from "../../../state/OtterKegState";
import { Spinner } from "@blueprintjs/core";
import { OrderedDrinkerList } from "./OrderedDrinkerList";
import { Pour } from "../../../types/Pour";
import { Drinker } from "../../../types/Drinker";
import { LiterboardStats } from "./LiterboardStats";

export const Literboard = React.memo(function Literboard() {
    let defaultStartDate = new Date()
    defaultStartDate.setDate(defaultStartDate.getDate() - 30)
    const [ dateRange, setDateRange ] = React.useState<DateRange>([defaultStartDate, new Date()]);
    const [ selectedDrinkerId, setSelectedDrinkerId ] = React.useState<string | undefined>(undefined);

    useFirebaseConnect([
        { 
            path: "pours", 
            storeAs: "allCompletedPours",
            queryParams: [ 'orderByChild=isCurrent', "equalTo=false" ] 
        },
        { path: "drinkers" }
    ])

    const pourRecords: Record<string, Pour> = useSelector((state: OtterKegState) => state.firebase.data.allCompletedPours)
    const drinkerRecords: Record<string, Drinker> = useSelector((state: OtterKegState) => state.firebase.data.drinkers)

    let loaded = isLoaded(pourRecords) && isLoaded(drinkerRecords)

    let pourArray: Pour[] = []
    let drinkerArray: Drinker[] = []
    if (loaded) {
        const [ startDate, endDate ] = dateRange
        pourArray = Object.keys(pourRecords).map((key) => {
            return {
                ...pourRecords[key],
                pourId: key
            }
        }).filter((pour) => {
            let pourDate = new Date(pour.start)
            return startDate! < pourDate && pourDate < endDate!
        })
        drinkerArray = Object.keys(drinkerRecords).map((key) => {
            return {
                ...drinkerRecords[key],
                drinkerId: key
            }
        })
    }

    return (
        <div className="literboard">
            <div className="date-selection">
                <DateRangeInput3 
                    value={dateRange}
                    onChange={(range) => {setDateRange(range)}}/>
            </div>
            { loaded ? 
            <div className="data-container">
                <OrderedDrinkerList 
                    pours={pourArray}
                    drinkers={drinkerArray}
                    selectedDrinkerId={selectedDrinkerId}
                    onDrinkerUpdate={setSelectedDrinkerId} />
                <LiterboardStats
                    pours={pourArray}
                    selectedDrinkerId={selectedDrinkerId} />
            </div>
            : <Spinner />}
        </div>
        );
});