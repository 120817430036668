import React from 'react'
import { OtterKegHeader } from "./common/OtterKegHeader";
import { KegView } from "./keg-view/KegView";
import { PourOverlayWrapper } from "./common/PourOverlayWrapper";
import { Login } from "./common/Login"
import { Literboard } from "./literboard/Literboard";
import "../../styles/otter-keg/OtterKeg.scss";
import { useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'
import { OtterKegState } from "../../state/OtterKegState";
import { OtterKegFooter } from './common/OtterKegFooter';
import { Routes, Route } from "react-router-dom";

export const OtterKeg = React.memo(function OtterKeg() {
    const auth = useSelector((state: OtterKegState) => state.firebase.auth)
    if (!isLoaded(auth)) {
        return <React.Fragment>
            <h1>Loading</h1>
        </React.Fragment>
    }
    if (auth.isEmpty) {
        return <Login />
    }
    return (
            <React.Fragment>
            <OtterKegHeader />
            <div className="otter-keg-body">
                <Routes>
                    <Route path='/' element={<KegView />} />
                    <Route path='literboard' element={<Literboard />} />
                </Routes>
            </div>
            <OtterKegFooter />
            <PourOverlayWrapper />
        </React.Fragment>
        
    )
})