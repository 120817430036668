import { BeerDetailsResponse } from "../types/BeerDetailsResponse";
import { KegWithDetails } from "../types/KegWithDetails";
import { store } from "../state/OtterKegState";
import { PourNarrativeResponse } from "../types/PourNarrativeResponse";

const base_url = "https://us-central1-otter-keg.cloudfunctions.net/"

function get(path: String): Promise<any> {
    console.log("Calling API path: ", path);
    let url = base_url + path
    let token = store.getState().firebase.auth.stsTokenManager.accessToken
    return fetch(url, {
        headers: {
            Authorization: "Bearer " + token
        }
    }).then(response => {
        if (!response.ok) {
            response.text().then(text => {
                console.log("Request error. Response body: ", text)
            })
            throw new Error(response.statusText)
        }
        return response.json();
    })
}

function post(path: String, body: any): Promise<any> {
    console.log("Calling API path: ", path);
    let url = base_url + path
    let token = store.getState().firebase.auth.stsTokenManager.accessToken
    return fetch(url, {
        headers: {
            "Authorization": "Bearer " + token,
            "Content-type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(body)
    }).then(response => {
        if (!response.ok) {
            response.text().then(text => {
                console.log("Request error. Response body: ", text)
            })
            throw new Error(response.statusText)
        }
        return response.json();
    })
}

function getAndSave(path: string): Promise<any> {
    return get(path).then(response => {
        let cachedData = {
            data: response,
            expiration: new  Date()
        }
        cachedData.expiration.setDate(cachedData.expiration.getDate() + 7);
        localStorage.setItem(path, JSON.stringify(cachedData));
        return Promise.resolve(response);
    })
}

function getCached(path: string): Promise<any> {
    let rawCachedResponse = localStorage.getItem(path);
    if (!rawCachedResponse) {
        return getAndSave(path);
    } else {
        let cachedResponse = JSON.parse(rawCachedResponse);
        if (new Date(cachedResponse.expiration) < new Date()) {
            console.log("Expired API response in cache for path: ", path)
            return getAndSave(path);
        } else {
            console.log("Fetching Untappd API request from cache: ", path);
            return Promise.resolve(cachedResponse.data);
        }
    }
}

export function getBeerDetails(bid: String): Promise<BeerDetailsResponse> {
    return getCached("/getBeerDetails?beerId=" + bid).then((rawResponse: any) => {
        let response : BeerDetailsResponse = rawResponse as BeerDetailsResponse;
        return Promise.resolve(response);
    })
}

export function getPourNarrative(name: string, poursToday: number, beerType: string, breweryLocation: string): Promise<PourNarrativeResponse> {
    return post("/getPourNarrative", {
        name: name,
        poursToday: poursToday,
        beerType: beerType,
        breweryLocation: breweryLocation
    }).then((rawResponse: any) => {
        let response : PourNarrativeResponse = rawResponse as PourNarrativeResponse
        return Promise.resolve(response)
    })
}

export function populateKegDetails(kegs: any[]) : Promise<KegWithDetails[]> {
    Object.entries(kegs).forEach(entry => {
        if (!entry[1].beerId.untappedBid) {
            console.log("Keg missing untappedBid!", entry)
        }
    });
    let promises = Object.entries(kegs).map(([id, keg]) => getBeerDetails(keg.beerId.untappedBid))
    return Promise.all(promises).then(beerDetailsResponses => {
        let newKegsWithDetails: KegWithDetails[] = []
        Object.keys(kegs).forEach((kegId: string) => {
            // maddie typescript made me do this i hate typescript why did you make me use it ugh
            let dumbTypescriptKegs: any = kegs;
            let keg: any = dumbTypescriptKegs[kegId];
            let beerDetails = beerDetailsResponses.filter((beerDetailsResponse) => {return beerDetailsResponse.untappdData.response.beer.bid === keg.beerId.untappedBid})[0]
            let kegWithDetails: KegWithDetails = {
                "position": keg.position,
                "kegId": kegId,
                "isActive": keg.isActive,
                "location": beerDetails.location,
                "sizeInLiters": keg.sizeInLiters,
                "beerDetails": beerDetails.untappdData.response.beer
            };
            newKegsWithDetails.push(kegWithDetails);
        });
        newKegsWithDetails.sort((a: any, b: any) => {
            let aValue = a.position === "left" ? 1 : a.position === "right" ? 2 : 3;
            let bValue = b.position === "left" ? 1 : b.position === "right" ? 2 : 3;
            return aValue - bValue;
        })
        return Promise.resolve(newKegsWithDetails);
    });
}
