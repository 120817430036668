import * as React from "react";
import QRCode from "react-qr-code";


export const UntappdQR = React.memo(function UntappdQR(props: {
    beerId: number
}) {
    let url = "https://untappd.com/qr/beer/" + props.beerId
    return (
        <QRCode value={url} 
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        viewBox={`0 0 256 256`}/>
        );
});