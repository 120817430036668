import * as React from "react";
import { useSelector } from "react-redux";
import { populate, useFirebaseConnect } from "react-redux-firebase";
import { OtterKegState } from "../../../state/OtterKegState";
import { KegWithDetails } from "../../../types/KegWithDetails";
import "../../../styles/otter-keg/PourOverlay.scss";
import { PourOverlay } from "./PourOverlay";

export const PourOverlayWrapper = React.memo(function PourOverlayWrapper() {
    const kegs: KegWithDetails[] = useSelector((state: OtterKegState) => state.kegs);
    const populates = [{ child: "drinkerId", root: "drinkers"}];

    let today = (new Date()).toLocaleString( 'sv', { timeZoneName: 'short' } ).substring(0, 10)
    useFirebaseConnect([{
        path: "pours", 
        populates, 
        queryParams: [ 'orderByChild=lastUpdate', 'limitToLast=1' ],
        storeAs: "latestPour"
    }, {
        path: "pours",
        queryParams: [ 'orderByChild=lastUpdate', 'startAt=' + today],
        storeAs: "todaysPours"
    }, {
        path: "drinkers",
        queryParams: [ 'orderByChild=isCurrentlySelected', 'equalTo=true'],
        storeAs: "currentlySelectedDrinker"
    }]);
    const pourRaw: any = useSelector((state: OtterKegState) => populate(state.firebase, "latestPour", populates));
    const todaysPours: any = useSelector((state: OtterKegState) => state.firebase.data.todaysPours)
    const currentlySelectedDrinker: any = useSelector((state: OtterKegState) => state.firebase.data.currentlySelectedDrinker)

    const pour = pourRaw != null ? {
        ...pourRaw[Object.keys(pourRaw)[0]],
        pourId: Object.keys(pourRaw)[0]
    } : undefined;
    const keg = pour != null ? kegs.filter((keg:KegWithDetails) => keg.kegId === pour.kegId)[0] : undefined;
    if (pour != null && keg != null) {
        return <PourOverlay pour={pour} keg={keg} todaysPours={todaysPours} currentlySelectedDrinker={currentlySelectedDrinker}/>
    } else {
        return null;
    }
})