import * as React from "react";
import "../../../styles/otter-keg/Literboard.scss";
import { Pour } from "../../../types/Pour";
import { Card, Elevation } from "@blueprintjs/core"
import { ComposedChart, ResponsiveContainer, Legend, XAxis , YAxis, Area, Label} from 'recharts';

interface PourChartDataPoint {
    date: Date,
    dailyVolume: number,
    totalVolume: number
}

export const LiterboardStats = React.memo(function LiterboardStats(props: {
    pours: Pour[],
    selectedDrinkerId: string | undefined,
}) {
    const { pours, selectedDrinkerId } = props;

    let poursToDisplay = selectedDrinkerId === undefined ? pours : pours.filter(pour => pour.drinkerId === selectedDrinkerId)
    let totalVolume = poursToDisplay.reduce((value, pour) => {
        return value + pour.amount
    }, 0)

    let poursByDay = new Map<string, Pour[]>()
    poursToDisplay.forEach(pour => {
        let fullPourDate = new Date(pour.start)
        let pourDate = new Date(Date.UTC(fullPourDate.getFullYear(),fullPourDate.getMonth(), fullPourDate.getDate())).toDateString();
        if (poursByDay.has(pourDate)) {
            poursByDay.get(pourDate)?.push(pour)
        } else {
            poursByDay.set(pourDate, [pour])
        }
    })
    let chartData: PourChartDataPoint[] = []
    poursByDay.forEach((pours, dateString) => {
        let dailyVolume = pours.reduce((total, pour) => {
            return total + pour.amount
        }, 0)
        chartData.push({
            date: new Date(dateString),
            dailyVolume: dailyVolume ?? 0,
            totalVolume: 0
        })
    })
    chartData = chartData.sort((a, b) => {
        return a.date.getTime() - b.date.getTime()
    })
    let total = 0
    chartData.forEach((value, _index) => {
        total = total + value.dailyVolume
        value.totalVolume = total
    })

    return (
        <div className="literboard-stats">
            <div className="stats-overview">
                <Card elevation={Elevation.FOUR}>
                    <h3>Number of Pours</h3>
                    <h2>{poursToDisplay.length}</h2>
                </Card>
                <Card elevation={Elevation.FOUR}>
                    <h3>Total Pour Volume</h3>
                    <h2>{totalVolume.toFixed(2)}</h2>
                </Card>
                <Card elevation={Elevation.FOUR}>
                    <h3>Average Pour Volume</h3>
                    <h2>{(totalVolume / poursToDisplay.length).toFixed(2)}</h2>
                </Card>
            </div>
            <div className="charts">
                <ResponsiveContainer width={"90%"} height={"80%"}>
                    <ComposedChart data={chartData}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.6}/>
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.6}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <YAxis></YAxis>
                        <XAxis dataKey="date">
                            <Label value={"Pours Over Time"} position='top' offset = {30} style={{ textAnchor: 'middle', fontSize: '2em', padding: '40px', fill: 'rgba(200, 200, 200, 0.87)' }}></Label>
                        </XAxis>
                        <Legend></Legend>
                        <Area type="monotoneX" dataKey="totalVolume" animationEasing='ease-out' stroke="#8884d8" fillOpacity={.9} fill="url(#colorUv)" dot={true} name={"Total Volume"}>
                            </Area>
                        <Area type="monotone" dataKey="dailyVolume" stroke="#82ca9d" fillOpacity={.9} fill="url(#colorPv)" dot={true} name={"Daily Volume"}/>
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
});