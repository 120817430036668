import * as React from "react";
import "../../../styles/otter-keg/OtterKegFooter.scss";
import { useFirebase, useFirebaseConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { OtterKegState } from "../../../state/OtterKegState";
import { Spinner } from "@blueprintjs/core";
import { DrinkerImage } from "./DrinkerImage";

export const OtterKegFooter = React.memo(function OtterKegFooter() {
    const firebase = useFirebase();
    useFirebaseConnect("drinkers");

    let drinkersRaw: any = useSelector((state: OtterKegState) => state.firebase.data.drinkers) ?? {};

    let drinkers = Object.keys(drinkersRaw).map((key, index) => {
        let drinker = {
            ...drinkersRaw[key]
        };
        drinker["drinkerId"] = key;
        return drinker
    }).filter(drinker => {
        return drinker.isEnabled
    })
    drinkers.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
    })

    function updateDrinker(drinkerId: string) {
        let currentlySelectedId = drinkers.filter(drinker => drinker.isCurrentlySelected)[0].drinkerId;
        let updates: any = {}
        updates[`drinkers/${currentlySelectedId}/isCurrentlySelected`] = false;
        updates[`drinkers/${drinkerId}/isCurrentlySelected`] = true;
        firebase.ref().update(updates);
    }
    return !!drinkers ? <div className="otter-keg-footer">
        <div>
            <p>Select a drinker before pouring:</p>
        </div>
        <div className="drinker-list">
        {drinkers.map((drinker: any, index: any) => {
            let activeClass = drinker.isCurrentlySelected ? "drinker-active": ""
            return <div className={`drinker ${activeClass}`} key={index} onClick={() => updateDrinker(drinker.drinkerId)}>
                <DrinkerImage pictureUrl={drinker.pictureUrl} />
                <p className="name">{drinker.name}</p>
            </div>
        })}
        </div>
        
    </div> : <Spinner />
});