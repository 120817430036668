import * as React from "react";
import { Classes, Overlay, Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import { UntappdQR } from "./UntappdQR";
import { getPourNarrative } from "../../../utils/functionUtils";

interface PourOverlayProps{
    pour: any;
    keg: any;
    todaysPours: any,
    currentlySelectedDrinker: any
}

export const  PourOverlay = React.memo(function PourOverlay(props: PourOverlayProps){
    const { pour, keg, todaysPours, currentlySelectedDrinker } = props;
    const { isCurrent, drinkerId: drinker, amount, pourId } = pour;

    const [ narrative, setNarrative ] = React.useState<string | undefined>(undefined)

    const [isOpen, setIsOpen] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (isCurrent) {
            setIsOpen(true);
        } else if  (!isCurrent) {
            // setTimeout(() => setIsOpen(false), 10000)
        }
    }, [isCurrent])

    React.useEffect(() => {
        if (isCurrent) {
            let drinkerId = Object.keys(currentlySelectedDrinker)[0]
            let userPours = (todaysPours === null || todaysPours === undefined) ? 0 : Object.entries(todaysPours).filter((a: any) => {
                return a[1].drinkerId === drinkerId
            }).length
            console.log("New pour just started")
            setNarrative(undefined)
            getPourNarrative(drinker.name, userPours, keg.beerDetails.beer_style, keg.beerDetails.brewery.location.brewery_city).then((response) => {
                console.log(response.narrative)
                setNarrative(response.narrative)
            }).catch(() => {
                console.log("Failed to load narrative")
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pourId, isCurrent])

    // Switching to liters!
    const title = isCurrent ? "Pouring" : "Pour Finished";
    return <div className="pour-overlay-wrapper">
        <Overlay isOpen={isOpen} usePortal={true} hasBackdrop={true} canOutsideClickClose={true} onClose={() => setIsOpen(false)}>
            <div className={classNames(Classes.CARD,
        Classes.ELEVATION_4,
        "overlay-container")}>
                <div>
                    <p className="title">{title}</p>
                </div>
                <div>
                    <p className="amount">{amount.toFixed(2)}</p>
                    <p>Liters</p>
                </div>
                <div className="details">
                    <p>Beer: {keg.beerDetails.beer_name}</p>
                    <p>Drinker: {drinker.name}</p>
                </div>
                <div className="extras">
                    <div className="gpt-output">
                        <h2>A note from your bartender...</h2>
                        { narrative === undefined ? 
                        <Spinner />
                        : <p>{narrative}</p> }
                    </div>
                    <div className="qr-code">
                        <p>Check in on Untappd!</p>
                        <div style={{height: "auto", margin: "0 auto", width: "auto", background: "white", padding: "10px"}}>
                        <UntappdQR beerId={keg.beerDetails.bid} />
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    </div>;
})