import React from 'react'
import { useFirebase } from 'react-redux-firebase'

export const Login = React.memo(function Login() {
    const firebase = useFirebase()
    function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            email: { value: string };
            password: { value: string };
        };
        const email = target.email.value;
        const password = target.password.value;
        console.log(email)
        firebase.login({
            email: email,
            password: password
        }).then(() => {
            // feeling lazy, would rather reload then deal with the state refresh
            window.location.reload();
        })
    }
    return (
        <React.Fragment>
            <h1>Please login</h1>
            <form method="post" onSubmit={handleSubmit}>
                <p>
                    <label>
                        Email: <input name="email" />
                    </label>
                </p>
                <p>
                    <label>
                        Password: <input name="password" type="password" />
                    </label>
                </p>
                <button type="submit">Login</button>
                
            </form>
        </React.Fragment>
    )
})