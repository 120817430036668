import * as React from "react";
import "../../../styles/otter-keg/Literboard.scss";
import { Drinker } from "../../../types/Drinker";
import { Pour } from "../../../types/Pour";

interface  AggregatedDrinker {
    isCurrentlySelected: boolean;
    isEnabled: boolean;
    name: string;
    drinkerId: string | undefined;
    pourCount: number;
    pourVolume: number
}

export const OrderedDrinkerList = React.memo(function OrderedDrinkerList(props: {
    drinkers: Drinker[],
    pours: Pour[],
    selectedDrinkerId: string | undefined,
    onDrinkerUpdate: Function
}) {
    const { drinkers, pours, selectedDrinkerId, onDrinkerUpdate } = props;

    let drinkersProcessed = drinkers.map((drinker: Drinker) => {
        let drinkerPours: Pour[] = pours.filter((pour: any) => pour.drinkerId === drinker.drinkerId)
        let pourCount = drinkerPours.length;
        let pourVolume = drinkerPours.reduce((acc: number, cur: any) => {
            return acc + cur.amount;
        }, 0);
        let drinkerProcessed: AggregatedDrinker = {
            ...drinker,
            pourCount: pourCount,
            pourVolume: pourVolume
        }
        return drinkerProcessed
    }).filter((drinker) => {
        return drinker.isEnabled
    })
    drinkersProcessed.sort((a, b) => {
        return b.pourVolume - a.pourVolume;
    })

    return (
        <div className="ordered-drinker-list">
            <div className="scroll-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Position</th>
                            <th>Name</th>
                            <th>Pour Volume (L)</th>
                        </tr>
                    </thead>
                    <tbody>
                    {drinkersProcessed.map((drinker: any, index: any) => {
                        let activeClass = drinker.drinkerId === selectedDrinkerId ? "active-row" : ""
                        return <tr className={activeClass} onClick={() => onDrinkerUpdate(drinker.drinkerId)} key={drinker.drinkerId}>
                            <td>{index + 1}</td>
                            <td>{drinker.name}</td>
                            <td>{drinker.pourVolume.toFixed(2)}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
});