import * as React from "react";
import "../../../styles/otter-keg/OtterKegHeader.scss";
import pbu from "../../../assets/pbu_40_white.png";
import { AnchorButton, Navbar } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";


export const OtterKegHeader = React.memo(function OtterKegHeader() {
    let navigate = useNavigate();
    return (
        <div className="otter-keg-header">
            <Navbar className="otter-keg-navbar" fixedToTop={true}>
                <Navbar.Heading>Otter Keg</Navbar.Heading>
                <Navbar.Divider />
                <AnchorButton className="bp5-minimal" icon="home" text="Home" onClick={() => navigate("/")}/>
                <AnchorButton className="bp5-minimal" icon="horizontal-bar-chart-desc" text="Literboard" onClick={() => navigate("/literboard")}/>
            </Navbar>
            <img src={pbu} alt="Powered by Untappd" />
        </div>
        );
});