import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { OtterKegState } from "../../../state/OtterKegState";
import "../../../styles/otter-keg/KegView.scss";
import { KegWithDetails } from "../../../types/KegWithDetails";
import { KegStats } from "./KegStats";
import { UntappdQR } from "../common/UntappdQR";

export const KegView = React.memo(function KegView() {
    let kegs: KegWithDetails[] = useSelector((state: OtterKegState) => state.kegs) || {};
    let activeKegs = kegs.filter(keg => keg.isActive);
    let [ showQRCode, updateShowQRCode ] = useState(false);

    return <div className={"keg-view"}>
        <div className={"keg-details"}>
            <div className="keg-details-row header">
                {activeKegs.map((keg, index) => {
                    return <div className="keg-details-col" key={index}>
                        <p className="beer-title">{keg.beerDetails.beer_name}</p>
                        <p>{keg.beerDetails.beer_style}<br />{keg.beerDetails.brewery.brewery_name} | {keg.beerDetails.brewery.location.brewery_city}, {keg.beerDetails.brewery.location.brewery_state}
                        <br />{keg.beerDetails.beer_abv}% ABV, {keg.beerDetails.beer_ibu} IBU <br /> </p> 
                    </div>
                })}
            </div>
            <div className="keg-details-row image">
                {activeKegs.map((keg, index) => {
                    return <div className="keg-details-col" key={index} onClick={() => updateShowQRCode(!showQRCode)}> {
                        showQRCode ? 
                        <div style={{height: "auto", margin: "0 auto", width: "auto", background: "white", padding: "10px"}}>
                        <UntappdQR beerId={keg.beerDetails.bid} />
                        </div>
                        : 
                        <img className="beer-logo" src={keg.beerDetails.beer_label_hd || keg.beerDetails.beer_label} alt={keg.beerDetails.beer_name}/>
                    }
                        
                    </div>
                })}
            </div>
            <div className="keg-details-row footer">
                {activeKegs.map((keg, index) => {
                    return <div className="keg-details-col" key={index}>
                        <KegStats keg={keg} />
                    </div>
                })}
            </div>
        </div>
    </div>
});