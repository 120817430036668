import * as React from "react";
import { useState } from "react";
import "../../../styles/otter-keg/OtterKegHeader.scss";
import { useFirebase } from "react-redux-firebase";
import default_icon from "../../../assets/default_icon.svg";

interface DrinkerImageProps{
    pictureUrl: string | undefined;
}

export const DrinkerImage = React.memo(function DrinkerImage(props: DrinkerImageProps) {
    const firebase = useFirebase();
    const { pictureUrl} = props;
    const [ resolvedUrl, setResolvedUrl ] = useState(undefined as string | undefined)

    if (pictureUrl !== undefined && resolvedUrl === undefined) {
        firebase.storage().ref(pictureUrl).getDownloadURL().then((url) => {
            setResolvedUrl(url);
        }).catch((error) => {
            console.error("Unable to load picture: " + error);
        })
    }

    if (resolvedUrl) {
        return <img src={resolvedUrl} alt={"Drinker"}/>
    } else {
        return <img src={default_icon} alt={"Default"}/>
    }
});